.btn-primary {
  background: #D62828;
  color: white;
  border: none;
  --bs-btn-hover-bg: #D62828;
}
body{
  font-size: 13px;
}

/* Sidebar  */
.sidebar {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  z-index: 9999;
  height: 100%;
  transition: left 0.3s ease;
}

.content {
  margin-left: 0;
  z-index: 9999;
  transition: margin-left 0.3s ease;
}

.sidebar.open {
  left: 0;
}

.content.open {
  margin-left: 250px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  cursor: pointer;
}
.header-mr {
  margin-top: -0.30rem !important
}

/* Added Custom Font for Roboto */
@font-face {
  font-family: 'RobotoRegular';
  src: url('../src/assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

/* Change FontSize based on screen size */
:root {
  --font-size-media-query: 16px;

}

/* Media query where you can update the font size */
@media (max-width: 767px) {
  :root {
    --font-size-media-query: 14px;

  }
}




/* Media query for medium screens (e.g., tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  :root {
    --font-size-media-query: 16px;

  }
}

/* Media query for large screens (e.g., desktops) */
@media (min-width: 992px) {
  :root {
    --font-size-media-query: 18px;

  }
}

.responsive-font-size {
  font-size: var(--font-size-media-query);
  
}

.border-col {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
}

.box {
  text-align: center;
  padding: 2px;
}

/* AutoComplete */
.search-list {
  width: 90%;
  max-height: 75px;
  overflow: scroll;
  position: absolute;
  z-index: 999;
}

.search-dropdown {
  position: relative;
}

.search-input-container {
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}

.font-bold {
  font-weight: bold;
}


/* Multiwidget Card  */
.square-card {
  position: relative;
  width: 100%;
  height: 100px;
  padding-bottom: 100%;
  overflow: hidden;
  border: 2px solid #005284
}

.square-card .square-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;

}

.square-quarter-screen {
  /* width: 25vw;  */
  height: 25vw;
}
.icons-round{
  padding: 4px;
    border-radius: 15px;
    background: #e1dede;
    margin-right: 5px;
 font-size: 20px;
}
.react-datepicker-wrapper{
  width: 100%;
}
.fixed-button{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background:#D62828;
  color: white;
  padding: 7px;
  box-shadow: 0px 0px 13px 3px #cecece;
}
.box-sha{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;

}

@-webkit-keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
  }

  to {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
